import axios from 'axios';

// const API_URL = 'http://localhost:3001/api/';
const API_URL = 'https://www.coutureducharme.fr/api/'

class services {
    sendMessage(name, phone, mail, message){
        return axios
            .post(API_URL + 'SendMessage', {name, phone, mail, message});
    };

    spaGift(from, to, mailSender, mailReceiver, telSender, telReceiver, message, number, creneau, sending){
        return axios
            .post(API_URL + 'SpaGift', {from, to, mailSender, mailReceiver, telSender, telReceiver, message, number, creneau, sending})
    };

};

export default new services();